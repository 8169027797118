























































































































































































































import Vue from "vue";
import SubHeader from "@/views/layout/header/SubHeader.vue";
import Search from "@/components/Search.vue";
import ModalLinkProgressChat from "@/components/modal/ModalLinkProgressChat.vue";
import { Component, Ref } from "vue-property-decorator";
import MenuFooter from "@/views/layout/footer/MenuFooter.vue";
import DraggableCal from "vue-draggable-cal";
import TicketModel from "@/models/createTicket/TicketModel";
import GroupModel from "@/models/GroupModel";
import { UserModule } from "@/store/modules/user";
import utils from "@/utils";
import api from "@/api";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
// import { Emit, Model } from "vue-property-decorator";
@Component({
  components: {
    SubHeader,
    Search,
    MenuFooter,
    ModalLinkProgressChat,
    DraggableCal,
    VueperSlides,
    VueperSlide,
  },
})
export default class ListTicket extends Vue {
  mounted() {
    this.loadTicket();
  }
  utils = utils;
  userDetail = UserModule.userDetail;
  modelValue: string = "";
  titlePage = "Ticket";
  nowDate = utils.general.formatDate("2022-05-09");
  subTitleSmallPage = "Open Ticket History";

  listTicket: TicketModel[] = [];
  groupUser: GroupModel = new GroupModel();

  @Ref() modalLink!: ModalLinkProgressChat;

  loadTicket() {
    return new Promise((resolve, reject) => {
      if (this.userDetail.accessgroup["name"] === "Operator") {
        api.ticket
          .getMyTask()
          .then((response) => {
            if (response.data.code === 200) {
              this.listTicket = [...response.data.data.data];
              resolve(response);
            }
          })
          .catch((err) => {
            reject(new Error(err));
          });
      } else {
        api.ticket
          .getMyTicket()
          .then((response) => {
            if (response.data.code === 200) {
              this.listTicket = [...response.data.data.data];
              resolve(response);
            }
          })
          .catch((err) => {
            reject(new Error(err));
          });
      }
    });
  }
  getTicketByDate(date: string) {
    return new Promise((resolve, reject) => {
      api.ticket
        .getMyTaskByDate(date)
        .then((response) => {
          if (response.data.code === 200) {
            this.listTicket = [...response.data.data.data];
            resolve(response);
          }
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  }

  showModal(idTicket: string) {
    this.modalLink.showModal(idTicket);
  }
  pitaStatus(statusColor: string) {
    let color = "";
    switch (statusColor) {
      case "new":
        color = "new";
        break;
      default:
        color = "on-progress";
    }
    return color;
  }
  colorStatus(statusColor: string) {
    let color = "";
    switch (statusColor) {
      case "new":
        color = "cl-red";
        break;
      default:
        color = "cl-green-light";
    }
    return color;
  }
}
