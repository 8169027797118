var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('search',{attrs:{"searchStatus":true}}),_c('div',{staticClass:"sub-header"},[_c('sub-header',{attrs:{"titlePage":_vm.titlePage,"subTitleSmallPage":_vm.subTitleSmallPage,"filter":true,"iconTitle":true}})],1),_c('div',{staticClass:"dragble-section"},[_c('draggable-cal',{on:{"selectedDate":function($event){return _vm.selectedDate($event)}}})],1),_c('section',{staticClass:"section-tab"},[_c('b-tabs',{staticClass:"tabs-ticket",attrs:{"content-class":"mt-3","align":"center"}},[_c('b-tab',{attrs:{"title":"Open","active":""}},[_c('b-container',[_vm._l((_vm.listTicket),function(item,i){return [(item.status !== 'close')?[_c('div',{key:i,staticClass:"card-ticket d-flex mb-3"},[_c('div',{class:'card-pita ' + _vm.pitaStatus(item.tiket_status)}),_c('div',{staticClass:"card-body"},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"title-card-ticket cl-medium-black mb-1"},[_c('h3',[_c('router-link',{attrs:{"to":{
                              name: 'detail-ticket',
                              params: { id: item.id },
                            }}},[_vm._v(" "+_vm._s(item.subject)+" ")])],1)]),_c('div',{staticClass:"date-ticket cl-medium-black"},[_vm._v(" "+_vm._s(_vm.utils.general.getFormattedDateIndoMonth( _vm.utils.general.unFormatDate(item.created_at) ))+" | "+_vm._s(_vm.utils.general.formatTime(item.created_at))+" ")])]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"4"}},[_c('div',{staticClass:"img-drug"},[_c('button',{staticClass:"btn-transparent",on:{"click":function($event){return _vm.showModal(item.id)}}},[_c('img',{attrs:{"src":require("@/assets/img/icon-click-modal.svg"),"alt":""}})])])])],1),_c('b-row',[_c('b-col',{staticClass:"remark-status cl-medium-black",attrs:{"cols":"6"}},[_c('img',{attrs:{"src":require("@/assets/img/korektif-black.svg"),"alt":""}}),_vm._v(" Korektif ")]),_c('b-col',{class:'remark-status text-right ' +
                        _vm.colorStatus(item.tiket_status),attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(item.tiket_status)+" ")])],1)],1)])]:_vm._e()]})],2)],1),_c('b-tab',{attrs:{"title":"Close"}},[_c('b-container',[_vm._l((_vm.listTicket),function(item,i){return [(item.status === 'close')?[_c('div',{key:i,staticClass:"card-ticket d-flex mb-3"},[_c('div',{class:'card-pita ' + _vm.pitaStatus(item.tiket_status)}),_c('div',{staticClass:"card-body"},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"title-card-ticket cl-medium-black mb-1"},[_c('h3',[_c('router-link',{attrs:{"to":{
                              name: 'detail-ticket',
                              params: { id: item.id },
                            }}},[_vm._v(" "+_vm._s(item.subject)+" ")])],1)]),_c('div',{staticClass:"date-ticket cl-medium-black"},[_vm._v(" "+_vm._s(_vm.utils.general.getFormattedDateIndoMonth( _vm.utils.general.unFormatDate(item.created_at) ))+" | "+_vm._s(_vm.utils.general.formatTime(item.created_at))+" ")])]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"4"}},[_c('div',{staticClass:"img-drug"},[_c('button',{staticClass:"btn-transparent",on:{"click":function($event){return _vm.showModal(item.id)}}},[_c('img',{attrs:{"src":require("@/assets/img/icon-click-modal.svg"),"alt":""}})])])])],1),_c('b-row',[_c('b-col',{staticClass:"remark-status cl-medium-black",attrs:{"cols":"6"}},[_c('img',{attrs:{"src":require("@/assets/img/korektif-black.svg"),"alt":""}}),_vm._v(" Korektif ")]),_c('b-col',{class:'remark-status text-right ' +
                        _vm.colorStatus(item.tiket_status),attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(item.tiket_status)+" ")])],1)],1)])]:_vm._e()]})],2)],1)],1)],1),_c('MenuFooter'),_c('modal-link-progress-chat',{ref:"modalLink"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }