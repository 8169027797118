export default class GroupModel {
  id: number | null;
  name: string | null;
  desc: string | null;
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
  constructor() {
    this.id = null;
    this.name = null;
    this.desc = null;
    this.deleted_at = null;
    this.created_at = null;
    this.updated_at = null;
  }
}
